import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC, useState, useCallback, useEffect } from 'react';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';
// import { Document, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
import PDFViewer from "./PDFViewer";
import DocSearchSettings from './DocSearchSettings';
import { SavedPromptExplore } from '../chat/SavedPromptExplore';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

//const RefPath = 'https://strnwvv6kcev3fg.blob.core.windows.net/content/Summary%20Plan%20Description%20-%20Cigna%20Dental%20Plan%20-%202022-16.pdf';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

interface ChatViewProps {
    // refPath?: string; // Make refPath an optional prop
}

//export const ChatView: FC = () => {
const ChatView: FC<ChatViewProps> = () => {
    //alert(RefPath);
    const classes = useClasses();
    const [showSavedPromptExplore, setShowSavedPromptExplore] = useState(false);
    const [showDocSearchSettings, setShowDocSearchSettings] = useState(false);
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    const [isExploreKPTsActive, setIsExploreKPTsActive] = useState(false); 
    const [chatListKey, setChatListKey] = useState(0); // Add this state


    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { fileViewerPath } = useAppSelector((state: RootState) => state.app);

    function handleClose() {
        setShowSavedPromptExplore(false);
    }

    function handleDocSettingsClose() {

    }

    const handleExploreKPTs = () => {
        setShowSavedPromptExplore(true);
        setShowDocSearchSettings(false);
    };

    const handlePromptClick = (promptId: string) => {
        // Set the selected prompt in localStorage
        localStorage.setItem(`SavedPrompt_${selectedId}`, promptId);

        // Close the SavedPromptExplore
        setShowSavedPromptExplore(false);
        //setIsExploreKPTsActive(false);

        // Update chatListKey to trigger rerender
        setChatListKey(prevKey => prevKey + 1);
    };    

    const resetAllStates = useCallback(() => {
        setShowSavedPromptExplore(false);
        
        // setShowDocSearchSettings(false);
        // setShowPDFViewer(false);
        // localStorage.setItem(`SavedPrompt_${selectedId}`, '');   
        // localStorage.setItem(`selectedContext_${selectedId}`, '');
        // localStorage.setItem(`selectedIcon_${selectedId}`, '');
        // localStorage.setItem(`selectedTitle_${selectedId}`, '');             
        // Reset any other states that need to be cleared when a new chat is created
    }, []);

useEffect(() => {
  const storedFileViewerPath = sessionStorage.getItem('FileViewerPath');
  const storedSelectedID = sessionStorage.getItem('SelectedID');
  if (storedFileViewerPath && storedSelectedID === selectedId) {
    setShowPDFViewer(true);
  } else {
    setShowPDFViewer(false);
  }
}, [fileViewerPath, selectedId]);

    return (
        <div className={classes.container}>
            <ChatList 
                key={chatListKey} // Add the key prop
                onExploreKPTs={handleExploreKPTs} 
                onChatItemClicked={handleClose} 
                onAddChat={resetAllStates}
                isExploreKPTsActive={isExploreKPTsActive} // Pass the state variable
                setIsExploreKPTsActive={setIsExploreKPTsActive} // Pass the setter function             
            />
            {showDocSearchSettings && <DocSearchSettings onClose={handleDocSettingsClose} />}
            {showSavedPromptExplore && <SavedPromptExplore onPromptClick={handlePromptClick} onClose={handleClose} />}
            {!showSavedPromptExplore && 
                <ChatWindow 
                    onExploreKPTs={handleExploreKPTs}
                    onDocChat={(context) => {
                        if (context === "Kwery iManage Collections") {
                            setShowDocSearchSettings(true);
                        } else {
                            setShowDocSearchSettings(false);
                        }
                        
                    }}
                    onAddChat={resetAllStates}
                    isExploreKPTsActive={isExploreKPTsActive} // Pass the state variable
                    setIsExploreKPTsActive={setIsExploreKPTsActive} // Pass the setter function
                />
            }
            {showPDFViewer && <PDFViewer onClose={() => setShowPDFViewer(false)} />}
        </div>
        
    );
};

export default ChatView;